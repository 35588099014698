:root {
  --cogna-red-dark: #B2463C;
  --cogna-red-lite: #E2A897;
  --cogna-white: #FFFFFF;
  --cogna-dark: #000000;
}

.bodyContactForm {
  min-height: 110vh;
  width: 100%;
  background-color: var(--cogna-red-lite);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1;
}

.containerContactForm {
  width: 100%;
  min-height: 107vh;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contentContactForm {
  background-color: #ffffffc0;
  width: 90%;
  margin-right: 15px;
  margin-left: 15px;
  min-height: 70%;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

#formContact {
  width: 90%;
}


.leftContactFormInfo {
  width: 280px;
  height: 280px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--cogna-white);
  border-radius: 50%;
  padding: 8px;
  z-index: 100;
  font-size: 0.7rem;
}

.leftContactForm img{
  width: 270px;
}

.leftContactForm{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 320px;
  margin: 50px 0;
}

.circleLeftContactForm1 {
  width: 300px;
  height: 300px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: -15%;
  left: 15%;
  opacity: 0.5;
}

.circleLeftContactForm2 {
  width: 300px;
  height: 300px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 8%;
  left: 15%;
  opacity: 0.5;
}

.circleLeftContactForm3 {
  width: 300px;
  height: 300px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: -10%;
  right: 5%;
  opacity: 0.5;
}

.circleLeftContactForm4 {
  width: 300px;
  height: 300px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 10%;
  right: 10%;
  opacity: 0.5;
}

.circleLeftContactForm5 {
  width: 300px;
  height: 350px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: -20%;
  right: 15%;
  opacity: 0.5;
}

.circleLeftContactForm6 {
  width: 300px;
  height: 300px;
  background-color: var(--cogna-red-dark);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  bottom: -20%;
  right: 20%;
  opacity: 0.5;
}