.QueriesConferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.queries-conferences-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}